<template>
  <div>
    <div class="banner" id="investor_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img :src="banner" /> -->
      <span
        >{{ $t("indexnav.Announcements and Circulars")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Announcements and Circulars</i
        ></span
      >
    </div>
    <!-- 跳转 -->
    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a> >
      <a href="/ir/default.html">{{ $t("indexnav.Investor Relations") }}</a> >
      {{ $t("indexnav.Announcements and Circulars") }} -->
      <bread-crumb />
    </section>
    <section>
      <div
        id="iframeContainer"
      >
      </div>
    </section>

    <!-- 未渲染 -->
    <div v-if="false">
      <!-- 选择年份 -->
      <section class="content" id="iframeContainer">
      
      </section>
      <!-- 内容 -->
      <section>
        <div class="loading_box" v-if="showLoading"></div>
        <div class="select-content" v-for="item in dataList" :key="item.id">
          <ul class="comm-list">
            <li>
              <span>{{ item.date }}</span>
              <span
                ><b class="ver-tc">{{ filterLang(item, "DeputyTitle") }}</b
                ><a :href="filterLang(item, 'FileUrl')" target="_blank">{{
                  filterLang(item, "Title")
                }}</a></span
              >
            </li>
          </ul>
        </div>
        <!-- <div class="block">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 15]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="50"
        >
        </el-pagination>
      </div> -->
      </section>
    </div>
  </div>
</template>

<script>
import { GongGao } from "@/api/investors";
import { getnoticedatalist, getnoticeyearlist } from "@/utils/clickyear";
import { filterLang } from "@/utils/LangChange";
import moment from "dayjs";
import { PageType } from "@/utils/constant";
import { getAction } from "@/api/manage";
import {ifarmeMixin} from "../mixin/ifarmeMixin"

export default {
  mixins: [
    ifarmeMixin
  ],
  /**
   * 前端：公告及通函列表页面
   */
  data() {
    return {
      showyear: false,
      dataList: [],
      yearList: [],
      resList: [],
      nowYear: "2022",
      showLoading: true,
      banner: "", 
      // ifarme 链接
      izh:'https://jinxinfertility.website.wisdomir.com/sc/ir_announcements.php',
     itr:'https://jinxinfertility.website.wisdomir.com/tc/ir_announcements.php',
     ien:'https://jinxinfertility.website.wisdomir.com/en/ir_announcements.php',
    };
  },
  created() {
    // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.GONGGAOJITONGHAN,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg;
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });

    GongGao().then((res) => {
      console.log(res);
      this.showLoading = false;
      this.resList = res.result;
      this.yearList = getnoticeyearlist(this.resList);
      this.nowYear = moment(new Date()).year();
      if (this.yearList instanceof Array && this.yearList.length > 0) {
        this.nowYear = this.yearList[0];
      }

      this.dataList = getnoticedatalist(this.resList, this.nowYear);
    });
  },
  mounted () {
     if(this.$store.state.nowlanguage=='zh'){
      this.setIframe(this.izh)
    }
     if(this.$store.state.nowlanguage=='tr'){
      this.setIframe(this.itr)
    }
     if(this.$store.state.nowlanguage=='en'){
      this.setIframe(this.ien)
    }
  },
  methods: {
    filterLang,
    checkyear() {
      this.showyear = !this.showyear;
    },
    ClickYear(year) {
      this.showyear = !this.showyear;
      if (this.nowYear == year) {
        return false;
      }
      this.dataList = getnoticedatalist(this.resList, year);
      this.nowYear = year;
    },
  },
};
</script>

<style scoped lang=less>
.banner {
  position: relative;
}
.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section,
article {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}
/* 选择年份 */
.content {
  margin: 3em auto 0 auto;
  > div:first-child {
    > div {
      display: block;
      float: left;
      color: #18325f;
      font-size: 1.3rem;
    }
    display: flex;
    justify-content: space-between;
    margin: 0 30px;
    border-bottom: 5px solid #18325f;
    > ul {
      position: relative;
      > li {
        color: #fff;
        cursor: pointer;
        background: #18325f;
        padding: 10px 58px 10px 15px;
        position: relative;
        > span {
          font-size: 14px;
        }
      }
      > ul {
        width: 100%;
        position: absolute;
        left: 0;
        text-align: center;
        border-radius: 0px !important;
        overflow: hidden;
        > li {
          font-size: 14px;
          padding: 14px 0;
          color: #fff;
          cursor: pointer;
          background: #18325f;
        }
        > li:hover {
          background: #747474 !important;
        }
      }
    }
  }
}
/* 三角 */
.content div > ul > li > span:after {
  content: "";
  width: 0px;
  height: 0px;
  display: block;
  position: absolute;
  top: 17px;
  right: 11px;
  z-index: 11;
  border-top: 8px solid #fff;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.content div:first-child > div {
  color: #18325f;
  font-size: 1.3rem !important;
  font: 14px/2 "宋体", Arial;
}
.select-content {
  margin: 0 30px;
  padding-bottom: 30px;
  overflow: hidden;
  /* min-height: 360px; */
  ul.comm-list {
    display: flex;
    font: 14px/2 "宋体", Arial;
    li {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #dddddd;
    }
  }
  ul.comm-list li span:first-child {
    width: 150px;
    float: left;
    font: 14px/2 "宋体", Arial;
    color: #666666;
  }
  ul.comm-list li span:last-child {
    display: table-cell;
    > a:hover {
      color: red;
    }
  }
  ul.comm-list li span b {
    color: #cccccc;
    display: block;
    font-weight: normal;
    font: 14px/2 Arial, Helvetica, sans-serif;
  }
  a {
    font: 14px/2 Arial, Helvetica, sans-serif;
    color: #666666;
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
@media (max-width: 520px) {
  ul.comm-list {
    li {
      width: 100%;
      span {
        width: 100% !important;
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .ab-item1 {
    font-size: 1.2em !important;
    padding: 0 1.5em 1.5em 1.5em !important;
  }
}
.ab-item1 {
  padding: 0 1.5em 1.5em 1.5em;
}
</style>
